import { render, staticRenderFns } from "./MyBreadcrumb.vue?vue&type=template&id=23b3ce2c&scoped=true&"
import script from "./MyBreadcrumb.vue?vue&type=script&lang=js&"
export * from "./MyBreadcrumb.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b3ce2c",
  null
  
)

export default component.exports