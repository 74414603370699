<template>
  <div>
    <a-breadcrumb style="padding:16px 0;background: #f0f2f5;">
      <a-breadcrumb-item v-for="item in breadcrumbs" :key="item.key">{{item.title}}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "MyBreadcrumb",
  props: {
    breadcrumbs: {
      default: () => {
        return new Array();
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  components: {},
  created() {},
  mounted() {},
};
</script>
<style lang='less' scoped>
</style>
