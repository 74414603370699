<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <div class="control_box">
        <div class="control_box_top">
          <div style="display: flex;align-items: center;">
            <a-page-header class="pt_0">开餐模式:</a-page-header>
            <a-radio-group v-model="queryOpenMeal.module">
              <a-radio :value="4">自选开餐</a-radio>
              <a-radio :value="1">每日开餐</a-radio>
              <a-radio :value="2">周日不开餐</a-radio>
              <a-radio :value="3">周一到周五开餐</a-radio>
            </a-radio-group>
            <a-button type="primary" style="margin-right: 10px" @click="updateOpenDate">更新</a-button>
          </div>
          <div class="flex" style="align-items: center;">
            <a-page-header class="pt_0">可提前报餐天数:</a-page-header>
            <a-input style="width:100px;" v-model="queryDay.max_meal"></a-input>
            <a-button type="primary" style="margin-left: 10px" @click="updateAheadDayFn">更新</a-button>
          </div>
        </div>
        <div v-if="queryOpenMeal.module == 4" style="display: flex; align-items: center;">
          <a-page-header class="pt_0">选择开餐日期:</a-page-header>
          <a-checkbox-group v-model="queryOpenMeal.week">
            <a-checkbox v-for="item in options" :key="item.key" :value="item.key">{{ item.label }}</a-checkbox>
          </a-checkbox-group>
        </div>
        <div class="control_box_bottom">
          <a-button type="primary" @click="tips" style="margin-right: 10px">添加特殊停餐日期</a-button>
        </div>
      </div>
      <div class="show_box">
        <a-table :columns="columns" :data-source="tableData" :loading="loading" :rowKey="(tableData) => tableData.id"
          :pagination="pagination" @change="handleTableChange">
          <template slot="is_open" slot-scope="record">{{ record.is_open == 0 ? "不开餐" : "开餐" }}</template>
          <template slot="action" slot-scope="record">
            <a-button type="link" @click="deleteFn(record)" >删除</a-button>
          </template>
        </a-table>
      </div>
    </div>

    <a-modal v-model="visible" title="添加特殊停餐日期" @ok="handleSure" okText="保存">
      <a-form-model :model="addParam" ref="addForm" :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }">
        <a-form-model-item label="设定日期">
          <a-date-picker @change="onChangeDate" placeholder="请选择日期" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
//引入接口api
import {
  suspension,
  suspensionDelete,
  suspensionCreate,
  weekplan,
  suspensionUpdate,
  getAheadDay,
  updateAheadDay,
} from "@/request/api";
//引入封装的组件
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
//引入工具函数
import util from "@/utils/util";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    MyBreadcrumb,
  },
  data () {
    return {
      //传给MyBreadcrumb的参数
      breadcrumbs: [
        {
          key: 0,
          title: "用餐人员管理",
        },
        {
          key: 1,
          title: "开餐日期",
        },
      ],
      options: [
        {
          key: 0,
          label: "周日",
        },
        {
          key: 1,
          label: "周一",
        },
        {
          key: 2,
          label: "周二",
        },
        {
          key: 3,
          label: "周三",
        },
        {
          key: 4,
          label: "周四",
        },
        {
          key: 5,
          label: "周五",
        },
        {
          key: 6,
          label: "周六",
        },
      ],
      queryOpenMeal: {
        module: "",
        week: [],
      },
      queryDay: {
        max_meal: "",
      },
      //请求参数
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      loading: false, //table加载状态

      // 表格的列配置
      columns: [
        {
          title: "日期",
          key: "susp_date",
          dataIndex: "susp_date",
        },
        {
          title: "类型",
          key: "is_open",
          scopedSlots: { customRender: "is_open" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      queryParam: {
        page: 1,
      },
      //页配置
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
      },
      visible: false,
      //更新参数
      addParam: {
        half_time: "",
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //获取表格数据
    getTableData () {
      this.loading = true;
      suspension().then((res) => {
        let resultData = res.data;
        // //处理页码
        const pagination = { ...this.pagination };
        pagination.total = resultData.total;
        this.pagination = pagination;
        //空列表判断
        if (resultData.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        //列表数据处理
        this.tableData = resultData.data;
        this.loading = false;
      });
    },

    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    tips () {
      this.$confirm({
        content: '若当天有报餐订单，创建特殊停餐日期将会对当天待就餐订单进行自动退餐，是否继续？',
        okText: '继续',
        onOk: () => {
          this.visible = true
        }
      })
    },

    handleSure: util.DebounceBy(function () {
      if (!this.addParam.half_time) {
        this.$message.info("请选择日期");
        return;
      }
      suspensionCreate(this.addParam)
        .then((res) => {
          this.$message.success("添加成功");
          this.visible = false;
          this.queryParam.page = 1;
          this.pagination.current = 1;
          this.getTableData();
        })
        .catch((err) => {
          let errorMsg = err.data.errors.title[0];
          this.$message.error(errorMsg);
        });
    }, 3000),

    deleteFn (record) {
      this.$confirm({
        content: `是否删除停餐日期${record.susp_date}`,
        onOk: () => {
          suspensionDelete(record.id)
            .then((res) => { })
            .catch((err) => {
              if (err.status == 204) {
                this.$message.success("删除成功");
                // this.queryParam.page = 1;
                // this.pagination.current = 1;
                this.getTableData();
              } else {
                this.$message.error(err.data.msg);
              }
            });
        },
      });
    },

    onChangeDate (val, str) {
      this.addParam.half_time = str;
    },

    getweekplan () {
      weekplan().then((res) => {
        this.queryOpenMeal = {
          module: res.data.status,
          week: res.data.week,
        };
      });
    },

    updateOpenDate () {
      this.$confirm({
        content:
          "若修改前开餐日期下有报餐订单，系统将进行自动退餐，请仔细核对开餐日期更新内容。仍然修改？",
        onOk: () => {
          suspensionUpdate(this.queryOpenMeal)
            .then((res) => {
              this.$message.success("更新成功");
              this.getweekplan();
            })
            .catch((err) => { });
        },
      });
    },

    getAheadDayFn () {
      getAheadDay().then((res) => {
        this.queryDay.max_meal = res.data.time_cycle;
      });
    },

    updateAheadDayFn () {
      updateAheadDay(this.queryDay)
        .then((res) => {
          this.$message.success("更新成功");
        })
        .catch((err) => {
          let errors = err.data.errors;
          for (let i in errors) {
            this.$message.error(errors[i]);
          }
        });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
    this.getweekplan();
    this.getAheadDayFn();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  background-color: #f0f2f5;
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_top {
      display: flex;
    }
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
.flex {
  display: flex;
}
.pt_0 {
  padding-top: 0;
}
</style>